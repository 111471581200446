<template>
  <component :is="previewComponent" :fileURL="media['s3_file_url']" />
</template>

<script>
import { lookup as mimeByExtension } from 'mime-types';
import PDFPreview from '@/components/MediaInput/components/FileInput/components/PDFPreview.vue';
import ExcelPreview from '@/components/MediaInput/components/FileInput/components/ExcelPreview.vue';

export default {
  name: 'DocumentView',
  components: { PDFPreview, ExcelPreview },
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  computed: {
    mimeType() {
      return mimeByExtension(new URL(this.media['s3_file_url']).pathname);
    },
    previewComponent() {
      return (
        {
          'application/pdf': PDFPreview,
        }[this.mimeType] || ExcelPreview
      );
    },
  },
};
</script>

<style lang="scss">
#ebook-view {
  #header {
    background: $grey-10;
  }
}
</style>
