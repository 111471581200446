<template>
  <div>
    <hs-alert
      class="mt-1 p-3"
      v-if="media.status === 'processing' || media.status === 'pending'"
      variant="primary"
      show
    >
      {{ $t('components.media-viewer.components.spark-videos-viewer.processing') }}
    </hs-alert>
    <b-embed v-else type="iframe" aspect="16by9" :src="this.sparkvideosUrl" allowfullscreen data-test="embed-video" />
  </div>
</template>

<script>
import { BEmbed } from 'bootstrap-vue';

export default {
  name: 'SparkVideosViewer',
  components: {
    BEmbed,
  },
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sparkvideosUrl() {
      const baseUrl = `${process.env.VUE_APP_SPARKVIDEOS_URL}/api/player`;
      const videoId = `${this.media.organization_uuid}/${this.media.host_key}`;
      const queryString = new URLSearchParams({
        auth: this.media.sparkvideos_auth ? this.media.sparkvideos_auth : '',
      });
      return `${baseUrl}/${videoId}?${queryString.toString()}`;
    },
  },
};
</script>
