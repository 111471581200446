<template>
  <div id="text-view">
    <div id="header" class="font-size-xs p-2">{{ media.title }}</div>
    <div id="text" class="p-3" v-html="media.text"></div>
  </div>
</template>

<script>
export default {
  name: 'TextView',
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
#text-view {
  #header {
    background: #f4f4f4;
  }
  #text {
    border: 1px solid #f4f4f4;
  }
}
</style>
