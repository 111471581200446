<template>
  <component :is="component" :media="media" v-on="$listeners" />
</template>

<script>
import YoutubeViewer from './components/YoutubeViewer';
import SparkVideosViewer from './components/SparkVideosViewer';
import VimeoViewer from './components/VimeoViewer';
import AudioViewer from './components/AudioViewer';
import DocumentViewer from './components/DocumentViewer';
import TextViewer from './components/TextViewer';
import ImageViewer from './components/ImageViewer';
import { capitalize } from '@/libs/string';

export default {
  name: 'MediaViewer',
  props: {
    media: {
      type: Object,
    },
  },
  computed: {
    component() {
      return (
        {
          Video: this.media.host + 'Viewer',
          Document: 'DocumentViewer',
        }[this.media.type] || capitalize(this.media.type) + 'Viewer'
      );
    },
  },
  components: {
    YoutubeViewer,
    SparkVideosViewer,
    VimeoViewer,
    AudioViewer,
    DocumentViewer,
    TextViewer,
    ImageViewer,
  },
};
</script>
