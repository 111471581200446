<template>
  <b-embed type="iframe" aspect="16by9" :src="`//player.vimeo.com/video/${this.media.host_key}`" allowfullscreen />
</template>

<script>
export default {
  name: 'VimeoVideoView',
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
};
</script>
