<template>
  <div id="audio-view">
    <p id="header" class="font-size-xs p-2">{{ media['s3_file_url'] }}</p>
    <div id="icon-container" class="d-flex justify-content-center align-items-center">
      <i class="fal fa-microphone"></i>
    </div>
    <audio controls class="w-100">
      <source :src="media['s3_file_url']" type="audio/mpeg" />
    </audio>
  </div>
</template>

<script>
export default {
  name: 'AudioView',
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
#audio-view {
  background: #a8a8a8;
  #header {
    background: #f4f4f4;
  }
  #icon-container {
    height: 220px;
    i {
      font-size: 35px;
    }
  }
  audio {
    background: #f4f4f4;
    margin-bottom: -6px;
  }
}
</style>
