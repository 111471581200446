










import { Component, Prop, Vue } from 'vue-property-decorator';
import { Optional } from '@/types/optional';
import { last } from 'lodash';

class URLReader {
  xhr = new XMLHttpRequest();
  onload: Optional<Function> = null;
  onerror: Optional<Function> = null;

  readURL(url: string) {
    const self = this;
    const xhr = this.xhr;
    xhr.responseType = 'blob';
    xhr.open('get', url);
    xhr.onload = function() {
      self.onload && self.onload(xhr.response);
    };
    xhr.onerror = function(event: any) {
      self.onerror && self.onerror(event);
    };
    xhr.send();
  }
}

const readerToPromise = function(ty: any, onSuccess: Function, onError: Function, readAs: Function) {
  return function(file: string) {
    return new Promise(function(resolved, rejected) {
      const reader = new ty();
      reader.onload = function(e) {
        resolved(onSuccess(file, e));
      };
      reader.onerror = function(e) {
        rejected(onError(file, e));
      };
      readAs(reader, file);
    });
  };
};

type T = { file: string; data: Blob };

const readRemoteFile = readerToPromise(
  URLReader,
  (file, event) => ({ file: file, data: event }),
  error => error,
  (reader, toRead) => reader.readURL(toRead)
) as (file: string) => Promise<T>;

@Component({})
export default class ExcelPreview extends Vue {
  @Prop({ type: String }) mimeType: string;
  @Prop({ type: String }) fileURL: string;

  get fileName(): string {
    return last(this.fileURL.split('/')) as string;
  }

  downloadFile(event: any) {
    event.preventDefault();
    event.stopPropagation();
    readRemoteFile(this.fileURL)
      .then(({ file, data }: T) => {
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        const uriSegments = file.split('/');
        const fileName = uriSegments[uriSegments.length - 1];
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch(error => console.error(error)); // eslint-disable-line no-console
  }
}
