<template>
  <div id="image-view">
    <div id="header" class="font-size-xs p-2">{{ media['s3_file_url'] }}</div>
    <b-img-lazy :src="media['s3_file_url']" width="300" />
  </div>
</template>

<script>
export default {
  name: 'ImageViewer',
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
#image-view {
  text-align: center;
  #header {
    background: #f4f4f4;
  }
}
</style>
