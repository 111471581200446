







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class PDFPreview extends Vue {
  @Prop({ type: String }) mimeType: string;
  @Prop({ type: String }) fileURL: string;
}
